<template>
  <div class="block">
    <label>
      <span v-if="required" class="text-red-600">*</span>
      {{ $t('validation.attributes.content') }}
    </label>
    <div class="border-l pl-2 my-2 space-y-4">
      <template v-for="(ct, ind) in form" :key="ind">
        <div :class="['relative pb-4 border-b border-dashed', `ct-${ct.type}`]">
          <template v-if="ct.type === 'text'">
            <v-quill v-model="form[ind].value" :options="{placeholder: $t('validation.attributes.content')}" no-media></v-quill>
          </template>
          <div v-else-if="ct.type === 'video'" class="block">
            <div class="grid grid-cols-3 gap-y-4 md:gap-y-0 md:gap-x-4">
              <div class="col-span-3 md:col-span-1">
                <i-form-item :label="$t('validation.attributes.video_url')" :desc="$t('adm.t.video_url-desc')">
                  <i-input type="url" v-model="form[ind].value" :placeholder="$t('adm.t.video_url')"></i-input>
                </i-form-item>
              </div>
              <div class="col-span-3 md:col-span-1">
                <i-form-item :label="$t('adm.t.desktop-height')" :desc="$t('adm.t.desktop-height-desc')">
                  <i-input v-model="form[ind].options.desktopHeight" type="text"></i-input>
                </i-form-item>
              </div>
              <div class="col-span-3 md:col-span-1">
                <i-form-item :label="$t('adm.t.mobile-height')" :desc="$t('adm.t.mobile-height-desc')">
                  <i-input v-model="form[ind].options.mobileHeight" type="text"></i-input>
                </i-form-item>
              </div>
            </div>

          </div>
          <div v-else-if="ct.type === 'image'" class="block">
            <div v-if="ct.value && ct.value[0]" class="grid grid-cols-2 gap-y-4 md:gap-y-0 md:gap-x-4">
              <div class="col-span-2 md:col-span-1 flex space-x-2">
                <i-image :src="ct.value[0]" type="cover" class="h-16"></i-image>
                <div class="text-sm text-gray-500 space-y-0.5">
                  <p class="break-all">{{ ct.value[0].name }}</p>
                  <p>w: {{ ct.value[0].dimensions.width || '' }} x h: {{ ct.value[0].dimensions.height || '' }}</p>
                  <p>{{ ct.value[0].file_size }} byte</p>
                </div>
              </div>
              <div class="col-span-2 md:col-span-1">
                <i-form-item :label="$t('adm.t.image-caption')">
                  <i-input v-model="form[ind].options.caption" type="text"></i-input>
                </i-form-item>
                <i-form-item :label="$t('adm.t.image-alt')">
                  <i-input v-model="form[ind].options.alt" type="text"></i-input>
                </i-form-item>
              </div>
            </div>
            <i-upload v-else v-model="form[ind].value" :target="target" :index="0" category="content" :target-id="targetId"></i-upload>
          </div>

          <div class="absolute flex flex-col right-1 top-1/2 z-50 transform -translate-y-1/2">
            <span v-if="ind > 0" @click="moveUp(ind)" class="text-cyan-600 cursor-pointer hover:text-cyan-700">
              <i class="fa fa-arrow-up"></i>
            </span>
            <span v-if="ind < (form.length - 1)" @click="moveDown(ind)" class="text-cyan-600 cursor-pointer hover:text-cyan-700">
              <i class="fa fa-arrow-down"></i>
            </span>
            <span v-if="form.length > 1" @click="itemDel(ind, ct)" class="text-red-600 cursor-pointer hover:text-red-700">
              <i class="fad fa-trash-alt"></i>
            </span>
          </div>
        </div>
      </template>
      <div v-if="limit === 0 || form.length <= limit" class="text-center mt-4 space-y-2 md:space-y-0">
        <i-button v-if="plugins.includes('text')" @click="addContent('text')" type="success" icon="fa fa-file-alt">
          {{ $t('nav.b.addText') }}
        </i-button>
        <i-button v-if="plugins.includes('image')" @click="addContent('image')" type="info" icon="fa fa-images">
          {{ $t('nav.b.insert-image') }}
        </i-button>
        <i-button v-if="plugins.includes('video')" @click="addContent('video')" type="danger" icon="fa fa-video-plus">
          {{ $t('nav.b.insert-video') }}
        </i-button>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent, nextTick, ref, watch} from 'vue'
import useUploadMixins from "@/mixins/upload";

export default defineComponent({
  name: 'IContent',
  props: {
    modelValue: {type: Array, default: () => {
      return [
        {type: 'text', value: null}
      ];
    }},
    //  Limit of content type object
    limit: {type: Number, default: val => {return val >>> 0;}},
    //  Upload target folder
    target: { type: String, default: 'News' },
    targetId: {type: Number, default: e => {
        return e >>> 0;
      }},
    //  Allow plugins of contents
    plugins: { type: Array, default: () => {return ['text', 'image', 'video'];}},
    required: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const form = ref(props.modelValue || [{type: 'text', value: null}])
    const {upDel} = useUploadMixins()

    const removeToolbar = () => {
      const removeChild = className => {
        const list = document.getElementsByClassName(className)
        list.forEach(val => {
          if (val.children) {
            val.children.forEach(e => {
              if (e.className.includes('ql-toolbar')) {
                e.remove()
              }
            })
          }
        })
      }

      removeChild('ct-image')
      removeChild('ct-video')
    }

    //  Add new Item
    const addContent = (e) => {
      let data = {type: e, value: null, options: {}}
      if (e === 'video') {
        data.options = {desktopHeight: '450px', mobileHeight: '300px'}
      }
      if (e === 'image') {
        data.options = {caption: null, alt: null, title: null, description: null}
      }
      form.value.push(data)
      nextTick(updateModel)
    }

    /**
     * Delete an item from Array | Object
     *-------------------
     * @param {Number} index - Index of Array|Object to delete
     * @param obj
     * @return {Array}
     *
     **/
    const itemDel = (index = 0, obj = null) => {
      form.value.splice(index, 1)

      if (obj.type === 'image' && obj.value && obj.value[0]) {
        //  Call to delete image via ID
        upDel(obj.value[0].id)
      }

      nextTick(removeToolbar)
    }
    //  Move item UP
    const moveUp = (index = 0) => {
      if (index === 0) {
        // if its the first in the array
        form.value.push(form.value.shift()); // same as option 1
      } else {
        const $temp = form.value[index - 1];
        form.value.splice(index - 1, 1, form.value[index]);
        form.value.splice(index, 1, $temp);
      }
      nextTick(removeToolbar)
    }
    //  Move item Down
    const moveDown = (index = 0) => {
      if (index === form.value.length - 1) {
        // if its the last in the list
        form.value.unshift(form.value.pop()); // same as option 1
      } else {
        const $temp = form.value[index + 1];
        form.value.splice(index + 1, 1, form.value[index]);
        form.value.splice(index, 1, $temp);
      }
      nextTick(removeToolbar)
    }

    /** Update model value */
    const updateModel = () => {
      emit('update:modelValue', form.value)
    }

    watch(() => form.value, () => {
      updateModel()
    })

    watch(() => props.modelValue, () => {
      form.value = props.modelValue
    })

    return {
      form,
      moveDown, moveUp, itemDel, addContent,
    }
  }
})
</script>
