<template>
    <SignIn v-if="token == null && !['auth-sign-in', 'auth-forgot'].includes(route.name)"></SignIn>
    <router-view v-else></router-view>
</template>

<script>
import {defineComponent, onMounted, provide} from 'vue'
import { useI18n } from 'vue-i18n'
import useState from "@/store";
import SignIn from '@/pages/auth/sign-in.vue'
import { useRoute } from "vue-router";

export default defineComponent({
    name: 'App',
    components: { SignIn },
    setup() {
        const { t } = useI18n({useScope: 'global'})
        const {token} = useState()
        const route = useRoute()

        provide('t', t)

        onMounted(() => {
            const plugin = document.createElement("script");
            plugin.setAttribute(
                "src",
                "https://ispa.io/docs/assets/all-icons.min.js"
            );
            plugin.async = true;
            document.head.appendChild(plugin);
        })

        return { t, token, route }
    }
})
</script>
