<template>
  <div class="block w-full">
    <div v-if="file" class="block pb-3 mb-3 border-b text-gray-700 font-bold">
      {{ $t('export.file-import-select').replace(':name', file.name).replace(':row', worksheet ? worksheet.rowCount : 0) }}
    </div>
    <div class="block pb-3 text-cyan-500 text-center font-bold text-lg">
      {{ $t('export.step-' + step) }}
    </div>
    <div>
      <div v-if="step < 2" @dragenter.prevent @drop.prevent="loadDropFile" @dragover.prevent="dragging = true" @dragleave="dragging = false" class="flex flex-col items-center px-6 py-12 border border-dashed rounded-md" :class="[dragging ? 'border-indigo-400 bg-indigo-50' : 'border-gray-400']">
        <span class="text-gray-400 text-2xl md:text-4xl mb-2"><i class="fa fa-file-plus"></i></span>
        <p class="text-lg text-gray-700">{{ $t('export.drop-file') }}</p>
        <p class="mb-3 text-gray-700">{{ $t('export.or') }}</p>
        <label class="bg-white cursor-pointer px-4 h-9 inline-flex items-center rounded border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
          {{ $t('export.select-file') }}
          <input id="import-file" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml" @change="loadFile" class="sr-only">
        </label>
      </div>
    </div>
    <div class="border-b pb-3">
      <div v-if="step === 2" class="block">
        <el-select v-model="headerLine" @change="setHeaderLine" :placeholder="$t('export.select-header-line')">
          <el-option v-for="i in Array.from({length: 10}, (_, i) => i + 1)" :key="i" :value="i">{{ $t('export.line-num').replace(':value', i) }}</el-option>
        </el-select>
        <div v-if="selectedHeader.length > 0" class="block mt-3">
          <label class="text-gray-800">{{ $t('export.selected-header') }}</label>
          <div class="border p-3 rounded space-x-2 space-y-2">
            <i-badge v-for="i in selectedHeader" :key="i">{{ i }}</i-badge>
          </div>
        </div>
      </div>
      <template v-if="step === 3">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <i-form-item v-for="item in v.importColumn" :key="item" :label="en.getEnumName(item) || $t('export.' + item)">
            <el-select v-model="setHeader[item]">
              <template v-for="head in selectedHeader" :key="head">
                <el-option v-if="head" :value="head">{{ head }}</el-option>
              </template>
            </el-select>
          </i-form-item>
        </div>
      </template>

      <template v-if="step === 4">
        <el-select v-model="bodyLine" @change="setBodyLine" :placeholder="$t('export.select-header-line')">
          <el-option v-for="i in Array.from({length: 10}, (_, i) => i + headerLine + 1)" :key="i" :value="i">{{ $t('export.line-num').replace(':value', i) }}</el-option>
        </el-select>
        <div v-if="selectedBody.length > 0" class="block mt-3">
          <label class="text-gray-800">{{ $t('export.selected-body') }}</label>
          <div class="border p-3 rounded space-x-2 space-y-2">
            <i-badge v-for="i in selectedBody" :key="i">{{ i }}</i-badge>
          </div>
        </div>
      </template>

      <div v-if="step > 1" class="block mt-3 text-right">
        <i-button @click="backStep" icon="fa fa-history">{{ $t('nav.b.back') }}</i-button>
        <i-button :loading="v.ld" type="primary" @click="nextStep" icon="fa fa-check">{{ $t('nav.b.confirm') }}</i-button>
      </div>

      <template v-if="step === 5 || step === 6">
        <el-table v-loading="v.ld" :data="step === 5 ? returnBody : v.ip.data" class="w-full" size="mini" lazy ref="multipleTable" @selection-change="e => checkAll(e)">
          <el-table-column v-if="step === 5" type="selection" :width="v.w.sl"></el-table-column>

          <template v-for="item in Object.keys(setHeader)" :key="item">
            <el-table-column :label="en.getEnumName(item)" #default="{row}">
              <span class="text-gray-700 font-medium" v-html="row[item]"></span>
            </el-table-column>
          </template>

          <el-table-column :width="v.w.op" class-name="text-base" #default="{row}">
            <span v-if="row.id > 0" class="float-right flex items-center pr-3">
              <!-- View & Report -->
              <template v-if="rule('species.viewAny')">
                <el-divider class="mx-3" direction="vertical"></el-divider>
                <el-tooltip :content="$t('nav.b.view')" effect="success" placement="top">
                  <router-link :to="{name: s.r.v, params: {id: row.id}}" :title="$t('nav.b.edit')">
                    <span class="text-green-600"><i class="el-icon-view"></i></span>
                  </router-link>
                </el-tooltip>
              </template>

              <template v-if="rule('species.update')">
                <el-divider class="mx-3" direction="vertical"></el-divider>
                <el-tooltip :content="$t('nav.b.edit')" effect="primary" placement="top">
                  <router-link :to="{name: s.r.u, params: {id: row.id}}" :title="$t('nav.b.edit')">
                    <span class="text-blue-500"> <i class="fa fa-edit"></i></span>
                  </router-link>
                </el-tooltip>
              </template>

              <template v-if="rule('species.delete')">
                <el-divider class="mx-3" direction="vertical"></el-divider>
                <el-popconfirm :title="$t('adm.t.delete-confirm')" @confirm="s.del(row.id)">
                  <template #reference>
                    <span class="cursor-pointer text-rose-500">
                      <i class="fa fa-trash-alt"></i>
                    </span>
                  </template>
                </el-popconfirm>
              </template>
            </span>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {Workbook} from "exceljs";
import useEnumerateMixins from "@/mixins/enumerate";
import {iAlert} from "@/mixins/utils";
import {useI18n} from "vue-i18n";

/**
 * Step 0: Select file to import
 * Step 1: after imported file => Let user set their own main header line
 * Step 2: Let user set header to field that we will import into database
 * Step 3: Show all the list with checkbox that user can check or uncheck the list
 * Step 4: Process to post to database and save! It's done.
 *
 * Todo: check xlsx or csv file
 *
 * @author malayvuong
 * @since 1.0.0
 */

export default defineComponent({
  name: 'IImport',
  props: {
    modelValue: {type: Object, default: {}},
  },
  setup(props) {
    const {t} = useI18n()
    const {en} = useEnumerateMixins()
    /** Draggable status */
    const dragging = ref(false)
    /** Set inner model value */
    const v = ref(props.modelValue || {})
    /** Set main header line */
    const headerLine = ref(null)
    /** Set the step of process import */
    const step = ref(1)
    /** Selected Header */
    const selectedHeader = ref([])
    /** File selected */
    const file = ref(null)
    /** Set header */
    const setHeader = ref({})
    /** body line */
    const bodyLine = ref(null)
    /** selected body line */
    const selectedBody = ref([])
    /** Returned body to post */
    const returnBody = ref([])
    /** Main worksheet */
    const worksheet = ref(null)

    /** Reset all data after success */
    const resetData = () => {
      headerLine.value = null
      step.value = 1
      selectedHeader.value = []
      file.value = null
      setHeader.value = {}
      bodyLine.value = null
      selectedBody.value = []
      returnBody.value = []
      worksheet.value = null
    }

    /** Back to previous step */
    const backStep = () => {
      //  Current step = 3 turn to 2 => reset setHeader
      if (step.value === 3) {
        setHeader.value = {}
      }
      if (step.value > 1) {
        step.value = step.value - 1
      }
    }

    /** Next step */
    const nextStep = () => {
      if ((headerLine.value === null || headerLine.value === undefined) && step.value === 2) {
        return iAlert(t('export.header-line-required'), true);
      }
      if ((setHeader.value[Object.keys(setHeader.value)[0]] === null || setHeader.value[Object.keys(setHeader.value)[0]] === undefined) && step.value === 3) {
        return iAlert(t('export.set-header-required'), true)
      }

      //  Plus step
      if (step.value < 6) {
        step.value = step.value + 1
      }

      //  Process to post data selected => call function import
      if (step.value === 6) {
        v.value.import()
        resetData()
      }

      //  Turn to next step
      if (step.value === 5) {
        parseBody()
      }
    }

    /** Set header line data */
    const setHeaderLine = () => {
      //  Reset current select header
      selectedHeader.value = []
      //  Begin set header
      const selectedLine = worksheet.value.getRow(headerLine.value)

      selectedLine.eachCell((cell, colNumber) => {
        selectedHeader.value.push(colNumber + '. ' + cell.value)
      })
    }

    /** Set body line selected */
    const setBodyLine = () => {
      //  Reset current select header
      selectedBody.value = []
      //  Begin set header
      const selectedLine = worksheet.value.getRow(bodyLine.value)

      selectedLine.eachCell((cell, colNumber) => {
        selectedBody.value.push(colNumber + '. ' + cell.value)
      })
    }

    /** Parse body data */
    const parseBody = () => {
      const body = []
      worksheet.value.eachRow((row, index) => {
        if (index >= (bodyLine.value || 1)) {
          const obj = {}

          for (const e in setHeader.value) {
            row.eachCell({includeEmpty: true}, (cell, colNumber) => {
              if (setHeader.value[e].indexOf(colNumber + '. ') >= 0) {
                obj[e] = cell.value
              }
            })
          }
          body.push(obj)
        }
      })
      returnBody.value = body
    }

    /** Process to load dropped file */
    const loadDropFile = event => {
      event.preventDefault()
      if (event.dataTransfer.files.length > 0) {
        loadFile(event.dataTransfer.files[0])
      }
    }
    /** Load file (input) to parse data */
    const loadFile = val => {
      if (!val) {
        return ;
      }

      file.value = val.target && val.target.files ? val.target.files[0] : val

      const wb = new Workbook()
      const reader = new FileReader()

      reader.readAsArrayBuffer(val.target && val.target.files ? val.target.files[0] : val)

      reader.onload = () => {
        const buffer = reader.result
        wb.xlsx.load(buffer).then(() => {
          worksheet.value = wb.worksheets[0]

          step.value = 2;
        })
      }
    }

    /** handleSelectionChange */
    const checkAll = val => {
      v.value.ip.data = val
    }

    //  Get enumerate list
    onMounted(() => {
      en.gets()
    })

    return {
      v, en, backStep, bodyLine, nextStep, setBodyLine, selectedBody, returnBody, checkAll,
      headerLine, step, selectedHeader, setHeaderLine, file, dragging, loadDropFile,
      setHeader,

      loadFile, worksheet,
    }
  },
})
</script>
