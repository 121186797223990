<template>
<label :class="[innerStatus === true ? 'text-green-500' : 'text-gray-500', disabled === true ? 'cursor-not-allowed' : 'cursor-pointer']">
  <i :class="nameIcon"></i>
  <input @change="updateValue" class="hidden" type="checkbox" v-model="innerStatus">
</label>
</template>

<script>
import {defineComponent, watch, ref} from "vue";

export default defineComponent({
  name: 'PowerSwitch',
  props: {
    modelValue: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    nameIcon: {type: String, default: 'fa fa-power-off'},
  },
  emits: ['update:modelValue', 'change'],
  setup(props, {emit}) {
    const innerStatus = ref(props.modelValue || false)
    const nameIcon = ref(props.nameIcon)

    /** Process to update */
    const updateValue = () => {
      if (props.disabled === false) {
        emit('update:modelValue', innerStatus.value)
        emit('change')
      }
    }

    watch(() => props.modelValue, () => {
      innerStatus.value = props.modelValue
    })

    return {
      innerStatus, nameIcon,
      updateValue,
    }
  }
})
</script>
