<template>
  <div class="space-y-6">
    <i-alert type="info" accent icon="fad fa-info-circle">
      <p>{{ $t('mgs.info.file-dimensions') }}</p>
    </i-alert>

    <div v-for="(i, ind) in innerList" :key="ind" class="sm:flex">
      <template v-if="i && i.id">
        <div class="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
          <i-image :src="i.thumb_url" class="h-32 w-full sm:w-32 border border-gray-300 bg-white text-gray-300"></i-image>
        </div>
        <div>
          <h4 class="text-lg break-all">{{ i.name }}</h4>
          <p class="mt-1 text-sm text-gray-400 break-all">
            {{ i.url }}
          </p>
          <div class="flex mt-3">
            <el-popconfirm :title="$t('adm.t.delete-confirm')" @confirm="upDel(i.id, innerList)">
              <template #reference>
                <i-button :loading="upLoading" type="danger" icon="fad fa-trash-alt" size="xs">{{ $t('nav.b.delete') }}</i-button>
              </template>
            </el-popconfirm>
          </div>
        </div>
      </template>
    </div>

    <i-upload v-if="isUpload" v-model="innerList" @change="updateModel" :target="target" :max="max" :category="category" :target-id="targetId"></i-upload>
  </div>
</template>
<script>
import {defineComponent, onMounted, ref, watch} from 'vue'
import useUploadMixins from "@/mixins/upload";

export default defineComponent({
  name: 'IImages',
  props: {
    modelValue: {type: [Object, Array], default: null},
    target: {type: String, default: null},
    max: {type: Number, default: 8},
    category: {type: String, default: 'images'},
    targetId: {type: Number, default: (e) => {
      return e >>> 0;
    }},
    isUpload: {type: Boolean, default: true},
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {upDel, upLoading} = useUploadMixins()
    const innerList = ref([])

    /** Update form */
    const updateModel = () => {
      emit('update:modelValue', innerList.value)
    }

    watch(() => props.modelValue, () => {
      innerList.value = props.modelValue
    })

    watch(() => innerList.value, () => {
      updateModel()
    })

    return {
      upLoading,
      updateModel,
      upDel, innerList,
    }
  }
})
</script>
