<template>
<div v-if="p.l.last_page > 1" class="bg-white py-3 flex items-center justify-between">
  <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 w-full">
    <div class="-mt-px w-0 flex-1 flex">
      <span v-if="p === 1" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-300 cursor-not-allowed">
        <ChevronLeftIcon class="mr-3 h-5 w-5 text-gray-300" aria-hidden="true"></ChevronLeftIcon>
        {{ $t('pagination.previous') }}
      </span>
      <a v-else @click="changePage(-1)" href="#" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
        <ChevronLeftIcon class="mr-3 h-5 w-5 text-gray-300" aria-hidden="true"></ChevronLeftIcon>
        {{ $t('pagination.previous') }}
      </a>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <template v-for="(page, ind) in p.l.last_page" :key="ind">
        <template v-if="p.l.last_page > 5">
          <template v-if="page === 1 || page === p.l.last_page">
            <a @click="p.p = page" :class="[p.p === page ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300']" href="#" class="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
              {{ $t(page === 1 ? 'pagination.first' : 'pagination.last') }}
            </a>
          </template>
          <template v-else>
            <template v-if="p.p > 3 && p.p < (p.l.last_page - 3) && (page > (p.p - 3) && page < (p.p + 3))">
              <a @click="p.p = page" :class="[p === page ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300']" href="#" class="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
                {{ page }}
              </a>
            </template>
            <template v-else>
              <template v-if="p.p < 4 && page < (p.p + 3)">
                <a @click="p.p = page" :class="[p.p === page ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300']" href="#" class="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
                  {{ page }}
                </a>
              </template>
              <template v-else-if="p.p > (p.l.last_page - 4) && page > (p.p - 3)">
                <a @click="p.p = page" :class="[p.p === page ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300']" href="#" class="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
                  {{ page }}
                </a>
              </template>
            </template>
          </template>
        </template>
        <template v-else>
          <a @click="p.p = page" :class="[p.p === page ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300']" href="#" class="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
            {{ page }}
          </a>
        </template>
      </template>
    </div>
    <div class="relative" v-if="p.l.last_page > 9">
      <el-select v-model="p.p" filterable placeholder="Jump to Page" class="absolute top-0 left-0 transform -translate-y-1/3" size="small" style="width: 100px">
        <el-option v-for="page in p.l.last_page" :key="page" :label="page" :value="page">
        </el-option>
      </el-select>
    </div>
    <div class="-mt-px w-0 flex-1 flex justify-end">
      <span v-if="p.p === p.l.last_page" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-300 cursor-not-allowed">
        {{ $t('pagination.next') }}
        <ChevronRightIcon class="ml-3 h-5 w-5 text-gray-300" aria-hidden="true"></ChevronRightIcon>
      </span>
      <a v-else @click="changePage(1)" href="#" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
        {{ $t('pagination.next') }}
        <ChevronRightIcon class="ml-3 h-5 w-5 text-gray-300" aria-hidden="true"></ChevronRightIcon>
      </a>
    </div>
  </nav>
</div>
</template>

<script>
import {defineComponent, onMounted, ref, watch} from 'vue'
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/vue/solid'

export default defineComponent({
  name: 'IPage',
  props: {
    modelValue: {type: Object, default: {}},
    publicSearch: {type: Boolean, default: false},
  },
  components: {ChevronLeftIcon, ChevronRightIcon},
  setup(props) {
    const loading = ref(false)
    const p = ref(props.modelValue)

    /** Change page num */
    const changePage = val => {
      if ((p.value.p > 1 && val < 0) || (p.value.p < (props.modelValue.l.last_page || 0) && val > 0)) {
        p.value.p += val
      }
    }

    watch(() => p.value.p, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        if (props.publicSearch) {
          p.value.publicSearch()
        } else {
          p.value.gets()
        }
      }
    })

    return {
      loading, p,
      changePage
    }
  },
});
</script>
