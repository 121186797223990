<template>
  <div v-if="user" class="relative flex items-center space-x-3">
    <div class="flex-shrink-0">
      <img class="h-10 w-10 rounded-full" src="@/assets/avatar.png" alt="">
    </div>
    <div class="flex-1 min-w-0">
      <div class="flex space-x-3">
        <span :class="[!user.status ? 'text-red-500' : '']" class="font-medium text-base">{{ user.email }}</span>
        <i-badge v-if="!user.email_verified_at" size="sm" type="warning" dot>{{ $t('account.notVerified') }}</i-badge>
        <i-badge v-else size="sm" type="success" dot>{{ $t('account.verified') }}</i-badge>
        <i-badge v-if="!user.status" size="sm" type="danger">{{ $t('account.locked') }}</i-badge>
      </div>
      <div class="block md:flex md:space-x-3 text-gray-400 text-xs">
        <span v-if="user.lastLogin" class="block md:flex">{{ $t('account.ovv.lastLogin') }}: <span class="text-gray-600 mx-2">{{ dateFormat(user.lastLogin.createdAt, 'YYYY-MM-DD HH:mm:ss') }}</span> - IP: {{ user.lastLogin.ipAddress }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import useState from "@/store";
import {dateFormat} from '@/mixins/utils'

export default defineComponent({
  name: 'AccountInfoBox',
  setup(props) {
    const {user} = useState()

    return {
      user,
      dateFormat
    }
  }
})
</script>
