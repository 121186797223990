import { createI18n } from 'vue-i18n'
import ajax from "@/ajax";
import {urlBase, supportLocales, appVersion} from '@/config/base'

export const SUPPORT_LOCALES = supportLocales

export function setupI18n(options = {locale: 'vi'}) {
  const innerOption = Object.assign({
    legacy: false,
    locale: 'vi',
    globalInjection: true
  }, options)
  const i18n = createI18n(innerOption)
  setI18nLanguage(i18n, innerOption.locale)
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
  //  Load translate key from ajax
  const url = urlBase + ((process.env.NODE_ENV !== 'production') ? `api/${appVersion}/i18n/` : `i18n/${appVersion}/`) + locale + '.json';
  const lang = await ajax.get(url).then(res => {
    return res
  })

  i18n.global.setLocaleMessage(locale, lang.data)
  // load locale messages with dynamic import
  // let messages;
  // messages = await import(/* webpackChunkName: "[request]" */`@/locales/${locale}.json`);
  //
  // // set locale and locale message
  // i18n.global.setLocaleMessage(locale, messages.default)
}
