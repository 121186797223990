import navConfig from './nav.config.json'
import {createRouter, createWebHistory} from 'vue-router'
import NProgress from "nprogress";
import {loadLocaleMessages, setI18nLanguage, setupI18n, SUPPORT_LOCALES} from './i18n.config'

const LOAD_MAP = {
    ['my']: path => {
        return () => import(/* webpackChunkName: "[request]" */ `@/pages/my${path}`)
    },
    ['auth']: path => {
        return () => import(/* webpackChunkName: "[request]" */ `@/pages/auth${path}`)
    },
    ['search']: path => {
        return () => import(/* webpackChunkName: "[request]" */ `@/pages/search${path}`)
    },
}
/** Load page by module */
const load = function(mod, path) {
    return LOAD_MAP[mod](path)
}

const registerRoute = navConfig => {
    const route = []
    Object.keys(navConfig).forEach((mod, index) => {
        const navs = navConfig[mod]

        route.push({
            path: `/${mod}`,
            redirect: { name: (mod === 'my') ? 'my-dashboard' : 'auth-sign-in' },
            component: load(mod, (mod === 'my') ? '/client' : (mod === 'search' ? '/search' : '/auth')),
            children: [],
        })

        navs.forEach(nav => {
            if (nav.href) return
            if (nav.children) {
                nav.children.forEach(e => {
                    if (e.children) {
                        const childLv1 = {
                            path: `/${mod}${e.path}`,
                            redirect: e.redirect,
                            component: load(mod, e.load),
                            children: [],
                        }

                        e.children.forEach(f => {
                            if (f.children) {
                                const childLv2 = {
                                    path: `/${mod}${e.path}${f.path}`,
                                    redirect: f.redirect,
                                    component: load(mod, f.load),
                                    children: [],
                                }
                                f.children.forEach(g => {
                                    const gChild = {
                                        path: g.path.slice(1),
                                        meta: {
                                            title: g.text || g.name,
                                            description: g.description || null,
                                            mod,
                                        },
                                        name: g.name,
                                        component: load(mod, g.load),
                                    }

                                    if (g.lang) {
                                        gChild.meta.lang = g.lang
                                    }

                                    childLv2.children.push(gChild)
                                })

                                childLv1.children.push(childLv2)
                            } else {
                                const child = {
                                    path: f.path.slice(1),
                                    meta: {
                                        title: f.text || f.name,
                                        description: f.description || null,
                                        mod,
                                    },
                                    name: f.name,
                                    component: load(mod, f.load),
                                }

                                if (f.lang) {
                                    child.meta.lang = f.lang
                                }

                                childLv1.children.push(child)
                            }
                        })
                        route[index].children.push(childLv1)
                    } else {
                        addRoute(e, mod, index)
                    }
                })
            } else {
                addRoute(nav, mod, index)
            }
        })
    })
    function addRoute(page, mod, index) {
        const component = load(mod, page.component || page.path)

        const child = {
            path: page.path.slice(1),
            meta: {
                title: page.text || page.name,
                description: page.description || null,
                mod,
            },
            name: page.name,
            component: component,
        }

        if (page.lang) {
            child.meta.lang = page.lang
        }

        route[index].children.push(child)
    }
    return route
}

let route = registerRoute(navConfig)

route = route.concat([{
    path: '/',
    redirect: { name: `my-dashboard` },
}, {
    path: '/:pathMatch(.*)*',
    redirect: { name: `my-dashboard` },
}])

const locale = 'vi'
export const i18n = setupI18n({locale: locale})

export const router = createRouter({
    history: createWebHistory(),
    routes: route,
})

let isLanguageLoaded = false

router.beforeEach(async (to, from, next) => {
    let paramsLocale = to.params.locale || 'vi'

    // use locale if paramsLocale is not in SUPPORT_LOCALES
    if (!SUPPORT_LOCALES.includes(paramsLocale)) {
        paramsLocale = 'vi'
    }

    if (!isLanguageLoaded) {
        // load locale messages
        await loadLocaleMessages(i18n, paramsLocale)
        isLanguageLoaded = true
    }

    // set i18n language
    setI18nLanguage(i18n, paramsLocale)

    next()
})

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.set(0.4)
    }
    next()
})

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})

export default {
    router,
    i18n
}
