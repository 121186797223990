<template>
  <div class="px-2 md:px-1 py-4 mb-12 h-80 flex flex-col items-center">
    <i-alert class="mx-auto" type="danger" icon="fad fa-exclamation-triangle" accent>
      <h2 class="mb-2 font-bold text-lg">{{ $t('auth.no.permission') }}</h2>
    </i-alert>
    <i-button type="warning" @click="router.push('/')" icon="fa fa-undo">{{ $t('nav.a.dashboard') }}</i-button>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'NoPermissionError',
  setup() {
    const router = useRouter()
    return {
      router
    }
  }
})
</script>
