<template>
  <div v-if="data && data.length > 0" class="flow-root">
    <ul role="list" class="-mb-8">
      <li v-for="(log, ind) in data" :key="ind">
        <div class="relative pb-8">
          <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
          <div class="relative flex items-start space-x-3">
            <div class="relative" v-if="log.creator" :class="[log.creator.avatar ? '' : 'px-1']">
              <template v-if="log.creator.avatar">
                <i-image :src="log.creator.avatar" class="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"></i-image>
                <span class="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                  <span class="h-5 w-5 text-gray-400 text-base text-center"><i :class="log.options.icon"></i></span>
                </span>
              </template>
              <template v-else>
                <div class="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                  <span class="h-5 w-5 text-gray-500 text-base text-center"><i :class="log.options.icon"></i></span>
                </div>
              </template>
            </div>
            <div class="relative px-1" v-else>
              <div class="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                <span class="h-5 w-5 text-gray-500 text-base text-center"><i :class="log.options.icon"></i></span>
              </div>
            </div>
            <div class="min-w-0 flex-1">
              <div>
                <div v-if="log.creator" class="text-sm">
                  <a href="#" class="font-medium text-gray-900">{{ log.creator.name }}</a>
                </div>
                <p class="text-sm text-gray-500" :class="[log.creator ? 'mt-0.5' : '']">
                  {{ $t(log.action) }} {{ $t('adm.t.at') }} {{ dateFormat(log.created_at, 'YYYY/MM/DD HH:mm:ss') }}
                </p>
              </div>
              <div class="mt-2 text-sm text-gray-700" v-if="log.changed">
                <div class="grid grid-cols-2 gap-4">
                  <p v-if="log.changed.from" class="p-3 border border-red-400 rounded">{{ log.changed.from }}</p>
                  <p v-if="log.changed.to" class="p-3 border border-green-400 rounded">{{ log.changed.to }}</p>
                </div>
              </div>
              <div class="mt-2 text-sm text-gray-700 flex space-x-4">
                <span><i class="fa fa-link"></i> {{ log.url }}</span>
                <span><i class="fa fa-map-marker-alt"></i> {{ log.ip_address }}</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <button v-else type="button" class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
    <span class="mx-auto h-12 w-12 text-gray-400 text-4xl">
      <i class="fa fa-comment-alt-minus"></i>
    </span>
    <span class="mt-2 block text-sm font-medium text-gray-900">
      {{ $t('mgs.info.no-logs') }}
    </span>
  </button>
</template>

<script>
import {defineComponent} from 'vue'
import {dateFormat} from '@/mixins/utils'

export default defineComponent({
  name: 'ILogs',
  props: {
    data: {type: Array, default: () => {
      return [];
    }}
  },
  setup(props) {

    return {
      dateFormat
    }
  }
})
</script>
