export const apiBase = (process.env.NODE_ENV === 'production') ? 'https://ddsh.pumat.vn/api/v1/admin/' : 'http://127.0.0.1:8000/api/v1/admin/'

export const urlBase = (process.env.NODE_ENV === 'production') ? 'https://ddsh.pumat.vn/' : 'http://127.0.0.1:8000/'

export const appVersion = '1.0.0'

export const supportLocales = ['vi', 'en']

export const baseTitle = "Vườn Quốc Gia Pù Mát"

export const logoUrl = 'https://qrguiding.com/qrguiding/logo-dark.png'
export const logoAuthUrl = 'https://pumat.vn/wp-content/uploads/2021/07/Logo1-e1626186239670.png'
