<template>
  <div class="flex items-center">
    <span v-show="!editable" @click="editableToggle"
          :class="[disabled ? 'cursor-not-allowed' : 'cursor-pointer', 'transition px-4 py-1.5 bg-gray-200 rounded hover:bg-orange-200']">
      {{ modelValue || innerValue || 'N/a' }}
    </span>
    <input v-show="editable" v-model="innerValue" :type="type"
           @focus="$event.target.select()"
           @blur="editable = false"
           @change="updateValue"
           class="rounded px-4 py-1.5 border border-gray-300 w-full text-sm focus:border-gray-400 focus:ring-0">
  </div>
</template>

<script>
import {defineComponent, onMounted, ref, watch} from "vue";
import {isNumber} from "element-plus/es/utils/util";

export default defineComponent({
  name: 'QuickEdit',
  props: {
    modelValue: {type: [String, Number], default: null},
    disabled: {type: Boolean, default: false},
  },
  emits: ['update:modelValue', 'change'],
  setup(props, {emit}) {
    const innerValue = ref(props.modelValue || null)
    const editable = ref(false)
    const type = ref('text')

    /** Update model value */
    const updateValue = () => {
      emit('update:modelValue', innerValue.value)
      emit('change')
    }
    /** Change editable status */
    const editableToggle = () => {
      if (props.disabled === false) {
        editable.value = !editable.value
      }
    }

    watch(() => props.modelValue, () => {
      innerValue.value = props.modelValue
    })

    onMounted(() => {
      if (isNumber(props.modelValue)) {
        type.value = 'number'
      }
    })

    return {
      innerValue, editable,
      updateValue, editableToggle,
      type,
    }
  }
})
</script>
