<template>
  <div class="bg-white pb-3 flex items-center justify-between">
    <div class="flex space-x-0 md:space-x-2 items-center">
      <template v-if="abs.multiSelect && abs.multiSelect.length > 0">
        <!-- Multiple update/change selection -->
        <el-select v-if="abs.action && abs.action.length > 0" v-model="abs.mu.col" @change="ShowDialog"
                   :placeholder="$t('adm.f.selectAction')" class="w-36" size="small">
          <el-option value="" disabled :label="$t('adm.f.selectAction')"></el-option>
          <el-option v-for="(act, ind) in abs.action" :key="ind" :value="act.col" :label="$t(act.label ? act.label : ('adm.t.change-' + act.name))">{{ $t(act.label ? act.label : ('adm.t.change-' + act.name)) }}</el-option>
        </el-select>
        <!-- Export button -->
        <el-button v-if="abs.isExport" type="success" @click="showExportDialog" size="small"><i class="fa fa-download"></i> {{ $t('nav.b.export') }}</el-button>
      </template>
    </div>
    <div class="flex space-x-2 items-center">
      <template v-for="(fil, ind) in abs.filter" :key="ind">
        <!-- Filter selection -->
        <div v-if="fil.search" class="flex items-center space-x-1">
          <el-select v-model="abs[fil.name]" @change="e => checkSearchable(e, fil.option)" filterable :placeholder="$t(fil.label || 'adm.f.select')" class="w-32" size="small">
            <el-option value="" :label="$t('adm.t.selectAll')"></el-option>
            <el-option v-for="item in fil.option" :key="item.id" :value="item.id" :label="item.mapped_name || item.name || $t(item.text)"></el-option>
          </el-select>
          <el-input v-if="searchFilter" v-model="abs.fq" @change="reload" placeholder="Search meta info" type="search" class="w-32" size="small"></el-input>
        </div>
        <el-select v-else v-model="abs[fil.name]" @change="reload" filterable
                   :remote="!!fil.lazy" :remote-method="e => fil.lazy.search(e)"
                   :placeholder="$t(fil.label || 'adm.f.select')" class="w-32" size="small">
          <el-option value="" :label="$t('adm.t.selectAll')"></el-option>
          <el-option v-for="item in fil.option" :key="item.id" :value="item.id" :label="item.mapped_name || item.name || $t(item.text)"></el-option>
          <template v-if="!!fil.lazy">
            <el-option v-for="item in fil.lazy.l.search" :key="item.id" :value="item.id" :label="item.mapped_name || item.name || $t(item.text)"></el-option>
          </template>
        </el-select>
      </template>
      <!-- Item per page selection -->
      <el-select v-model="abs.lm" @change="updateLimit" class="w-20" size="small">
        <el-option v-for="item in abs.lmOption" :key="item.value" :value="item.value" :label="item.text"></el-option>
      </el-select>
      <!-- Search query input -->
      <el-input v-model="abs.q" @change="updateQuery(abs.q)" :placeholder="$t('adm.f.search')" type="search" class="w-48" suffix-icon="fa fa-search" size="small">
      </el-input>
    </div>
  </div>

  <!-- Dialog show to export file from list -->
  <el-dialog v-if="abs.isExport" :title="$t('nav.b.export')" v-model="exportFile.modal" width="50%">
    <div class="block">
      <el-checkbox :indeterminate="isCheck" v-model="checkAllStatus" @change="checkAll">{{ $t('adm.f.select-all') }}</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checked" @change="checkedChange">
        <el-checkbox v-for="h in abs.ep.header" :label="h" :key="h">{{ en.getEnumName(h) || $t('export.' + h) }}</el-checkbox>
      </el-checkbox-group>
      <div class="block pt-4">
        <i-form-item :label="$t('export.title')">
          <i-input v-model="abs.ep.title" :placeholder="$t('export.title')"></i-input>
        </i-form-item>
        <i-form-item :label="$t('adm.f.file-name')" :desc="`<span class='text-green-600'>${abs.ep.name}.${abs.ep.type}</span> (${$t('adm.t.file-name')})`">
          <div class="flex items-center space-x-2">
            <div class="flex-1">
              <i-input v-model="abs.ep.name"></i-input>
            </div>
            <el-select class="mt-1" v-model="abs.ep.type">
              <el-option value="xlsx">Excel</el-option>
              <el-option value="csv">CSV</el-option>
            </el-select>
          </div>
        </i-form-item>
      </div>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <i-button @click="exportFile.modal = false" icon="fa fa-times">{{ $t('nav.b.cancel') }}</i-button>
        <i-button type="danger" @click="exportExcel" icon="fa fa-download">{{ $t('nav.b.export') }}</i-button>
      </span>
    </template>
  </el-dialog>
  <!--  end dialog export -->

  <!-- Dialog show to quick edit multiple selection -->
  <el-dialog :title="$t('adm.t.multi-update')" v-model="abs.multiModal" @close="resetMulti" width="50%">
    <div class="flex items-center">
      <i-form-item class="mx-auto w-60 max-w-full" :label="$t(currentObject.label ? currentObject.label : ('adm.t.change-' + currentObject.name))" :desc="`${currentObject.lazy ? $t('adm.t.keyword') : ''}`">
        <el-select v-if="!currentObject.pickDate" v-model="abs.mu.value" filterable
                   :remote="!!currentObject.lazy" :remote-method="e => {e && currentObject.lazy ? currentObject.lazy.search(e) : []}"
                   :placeholder="$t(currentObject.label || 'adm.f.select')" class="w-full">
          <el-option value="" disabled :label="$t('adm.f.select')"></el-option>
          <el-option v-if="currentObject.option" v-for="item in currentObject.option" :key="item.id" :value="item.id" :label="item.mapped_name || item.name || $t(item.text)"></el-option>
          <el-option v-if="!!currentObject.lazy" v-for="item in currentObject.lazy.l.search" :key="item.id" :value="item.id" :label="item.mapped_name || item.name || $t(item.text)"></el-option>
        </el-select>
        <el-date-picker v-else v-model="abs.mu.value" type="date"></el-date-picker>
      </i-form-item>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <i-button @click="abs.multiModal = false" icon="fa fa-times">{{ $t('nav.b.cancel') }}</i-button>
        <i-button :disabled="!abs.mu.value" type="danger" @click="abs.quickUpdateMulti()" icon="fad fa-save">{{ $t('nav.b.save') }}</i-button>
      </span>
    </template>
  </el-dialog>
<!--  end dialog multiple selection -->
</template>

<script>
//  ACTION DEFAULT ARRAY
//  ['status', 'category', 'published-date', 'author', 'species', 'storage', 'storage-status', 'price', 'flash-sale', 'brand', 'tags']
import {defineComponent, onMounted, ref} from 'vue'
import {Workbook} from "exceljs";
import FileSaver from 'file-saver'
import {dateFormat} from "@/mixins/utils";
import useEnumerateMixins from "@/mixins/enumerate";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: 'IFilter',
  props: {
    modelValue: {
      type: Object, default: () => {
        return {q: null, p: 1, lm: 30, l: {data: []}}
      }
    },
  },

  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {t} = useI18n()
    const {en} = useEnumerateMixins()
    /** Abstract class model */
    const abs = ref(props.modelValue)
    const value = ref({})
    /** Set all checked value */
    onMounted(() => {
      abs.value.ep.title = abs.value.tb + ' Export'
      checked.value = abs.value.ep.header || []
    })
    /** Update Model value */
    const updateLimit = () => {
      abs.value.p = 1
      updateModel()
      reload()
    }
    /** Searchable after select filter */
    const searchFilter = ref(false)
    const searchKey = ref(null)

    const checkSearchable = (id, option) => {
      const item = option.find(e => e.id === id)
      if (item) {
        searchFilter.value = true
      }
    }
    //  End Searchable

    /** Multiple update action */
    const currentObject = ref(null)
    const ShowDialog = col => {
      const obj = abs.value.action.find(e => e.col === col)

      if (obj) {
        currentObject.value = obj
        abs.value.mu.type = obj.type
      }

      abs.value.multiModal = true
    }
    const resetMulti = () => {
      abs.value.mu.col = null
      abs.value.mu.value = null
    }
    //  End multiple update

    /** Export file */
    const checkAllStatus = ref(true)
    const checked = ref([])
    const isCheck = ref(false)
    const exportFile = ref({name: '', modal: false, bgColor: '4ADE80'})
    //  Process before show dialog
    const showExportDialog = () => {
      abs.value.ep.name = abs.value.tb + '-' + dateFormat(new Date(), 'YYYY-MM-DD-HH-mm-ss')
      exportFile.value.modal = true
    }
    const checkAll = val => {
      checked.value = val ? abs.value.ep.header : [];
      isCheck.value = false;
    }
    const checkedChange = val => {
      let checkedCount = val.length;
      checkAllStatus.value = checkedCount === abs.value.ep.header.length;
      isCheck.value = checkedCount > 0 && checkedCount < abs.value.ep.header.length;
    }

    /** Update Model value */
    const updateQuery = val => {
      abs.value.q = val
      updateModel()
      reload()
    }

    const reload = () => {
      abs.value.gets()
    }

    const updateModel = () => {
      emit('update:modelValue', abs.value)
    }
    /** Process to export */
    const exportExcel = () => {
      //  Create workbook and worksheet
      let workbook = new Workbook();  //  Create new file
      //  Set workbook info
      workbook.creator = 'QR Guiding Inc.'
      workbook.title = 'Exported list: ' + abs.value.tb
      workbook.company = 'QR Guiding Inc.'
      workbook.manager = 'iSPA Team (ispa.io)'
      workbook.description = 'export code to excel file by malayvuong & phamanhphien (iSPA Team)'

      let worksheet = workbook.addWorksheet(abs.value.tb);  //  Create new sheet with table name
      //Add Row and formatting
      worksheet.addRow([]); //  Add new blank row
      let titleRow = worksheet.getRow(1); //  Get the first row
      titleRow.font = {name: 'Times New Roman', family: 4, size: 16, bold: true}
      titleRow.alignment = {vertical: 'middle', horizontal: 'center'}

      worksheet.mergeCells('A1:' + getColumnName(checked.value.length) + '2');  //  Merge cell with total column by export header (count export header)

      //  Begin add Header Row
      //  Init column
      const columns = []
      checked.value.forEach((e, index) => {
        const idCol = worksheet.getColumn(index + 1);
        idCol.hidden = false
        idCol.header  = en.getEnumName(e)
        idCol.key = e
        idCol.width = 24
        if (['description', 'resources'].includes(e)) {
          idCol.width = 32
        } else if (['image', 'qrcode'].includes(e)) {
          idCol.width = 8
        }
        columns.push(idCol)
      })
      //  Make header text
      worksheet.columns = columns
      //  Insert header line
      let header = []
      checked.value.forEach((e, index) => {
        header.push(en.getEnumName(e) || t('export.' + e))
      })
      let headerRow = worksheet.addRow(header);
      // Header Cell Style: Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: exportFile.value.bgColor || 'FFFFFF00'},
          bgColor: {argb: exportFile.value.bgColor || 'FF0000FF'}
        }
        cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
      })

      function stripHtml(html)
      {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
      }

      // set data (table, image) in sheet
      abs.value.multiSelect.forEach((f, index) => {
        let newItem = {}
        checked.value.forEach(e => {
          if (['image', 'qrcode'].includes(e)) {
            if (e === 'image') {
              //  Begin import image
              let image = document.getElementById('export_img_' + f.id)
              if (image && image.src) {
                abs.value.getImage(image, index, header.length -2, workbook, worksheet, 5)
              }
            } else {
              //  Begin import QR Code
              let qr = document.getElementById('qr-'+f.id+'-'+f.uid)
              if (qr && qr.src) {
                abs.value.getImage(qr, index, header.length -1, workbook, worksheet, 5)
              }
            }
          } else
          {
            if(e.indexOf('.') > 0) {
              const exp = e.split('.')
              let item = {}
              //  Check object or Array
              if (f[exp[0]]) {
                if (typeof f[exp[0]] === 'object') {
                  Object.keys(f[exp[0]]).forEach(index => {
                    if (f[exp[0]][index].name === exp[1]) {
                      item['value'] = f[exp[0]][index].value
                    }
                  })
                } else {
                  item = f[exp[0]].find(g => (g.name === exp[1]))
                }
                if (Object.keys(item).length > 0) {
                  newItem[e] = item.value
                }
              }
            } else {
              if (e === 'name_html') {
                newItem[e] = stripHtml(f[e])
              } else {
                if (f[e]) {
                  if (typeof f[e] === 'object') {
                    newItem[e] = f[e].name_vietnamese || f[e].name || ''
                  } else {
                    newItem[e] = f[e]
                  }
                }
              }
            }
          }
        })
        //  Add new row
        const row = worksheet.addRow(newItem);
        //  Set custom Row height
        if (newItem.qrcode !== null || newItem.image !== null) {
          row.height = 40
          row.alignment = {vertical: 'middle'}
        } else {
          row.height = 8
        }
      })

      //  Set title Row
      titleRow.getCell(1).value = abs.value.ep.title
      //  Make file name to export
      const fileName = (abs.value.ep.name || abs.value.tb) + '.' + abs.value.ep.type
      if (abs.value.ep.type === 'xlsx') {
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          FileSaver.saveAs(blob, fileName);
        })
      } else {
        workbook.csv.writeBuffer().then(data => {
          let blob = new Blob([data], {type: 'text/csv'});
          FileSaver.saveAs(blob, fileName);
        })
      }
    }

    /** Get column name by total of column header */
    const getColumnName = val => {
      const arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

      return arr[(val-1)] || 'Z';
    }

    return {
      //value id selected
      value,
      //Show function
      ShowDialog, resetMulti, currentObject,
      //  Filter function
      updateLimit, updateQuery, reload,
      //  Search by after select filter
      searchFilter, checkSearchable, searchKey,
      //  Export handle
      exportExcel, checkedChange, checkAll,
      exportFile, showExportDialog,
      //  Check all export
      checkAllStatus, checked, isCheck,
      //  Inner function
      abs, en, getColumnName,
    }
  },
});
</script>
