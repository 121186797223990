<template>
  <section ref="editor"></section>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Quill from 'quill'
import { defineComponent, onMounted, ref, watch, onUnmounted } from 'vue'

const defaultOptions = {
  theme: 'snow',
  boundary: document.body,
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['clean'],
    ]
  },
  placeholder: 'Insert text here ...',
  readOnly: false
}

export default defineComponent({
  name: 'VQuill',
  props: {
    content: String,
    modelValue: String,
    disabled: {type: Boolean, default: false},
    options: {type: Object, required: false, default: () => ({})},
    id: {type: [Number, String], default: 1},
    noMedia: {type: Boolean, default: false},
  },
  emits: ['ready', 'change', 'input', 'blur', 'focus', 'update:modelValue'],
  setup(props, context) {
    const state = {editorOption: {}, quill: null}
    let _content = ''
    const editor = ref(null)
    const mergeOptions = (def, custom) => {
      for (const key in custom) {
        if (!def[key] || key !== 'modules') {
          def[key] = custom[key]
        } else {
          mergeOptions(def[key], custom[key])
        }
      }
      return def
    }
    const initialize = () => {
      if (editor.value) {
        // Options
        state.editorOption = mergeOptions(defaultOptions, props.options)
        // Instance
        state.quill = new Quill(editor.value, state.editorOption)
        // Set editor content
        if (props.modelValue) {
          state.quill.pasteHTML(props.modelValue)
        }
        // Mark model as touched if editor lost focus
        state.quill.on('selection-change', range => {
          if (!range) {
            context.emit('blur', state.quill)
          } else {
            context.emit('focus', state.quill)
          }
        })
        // Update model if text changes
        state.quill.on('text-change', () => {
          // disabled editor after content initialized
          if (props.disabled) {
            state.quill.enable(false)
          }
          let html = editor.value.children[0].innerHTML
          const quill = state.quill
          const text = state.quill.getText()
          if (html === '<p><br></p>') html = ''
          _content = html
          context.emit('update:modelValue', _content)
          context.emit('change', { html, text, quill })
        })
        // Emit ready event
        context.emit('ready', state.quill)
      }
    }

    watch(() => props.modelValue, val => {
      if (state.quill) {
        if (val && val !== _content) {
          _content = val
          state.quill.pasteHTML(val)
        } else if (!val) {
          state.quill.setText('')
        }
      }
    })
    watch(() => props.content, val => {
      if (state.quill) {
        if (val && val !== _content) {
          _content = val
          state.quill.pasteHTML(val)
        } else if (!val) {
          state.quill.setText('')
        }
      }
    })

    watch(() => props.disabled, val => {
      if (state.quill) {
        state.quill.enable(!val)
      }
    })

    onMounted(() => {
      if (props.noMedia === false) {
        defaultOptions.modules.toolbar.push(['link', 'image', 'video'])
      }
      initialize()
    })

    onUnmounted(() => {
      state.quill = null
    })

    return { editor }
  }
})
</script>
<style>
.ql-container, .ql-editor {
  min-height: 400px;
}
.ql-editor p {
  line-height: 1.5; margin-bottom: 0.25rem;
}
</style>
