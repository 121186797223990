<template>
  <div>
    <img v-if="src" @click="open = true" :src="url" alt=" " class="flex-shrink-0 border rounded" crossorigin="anonymous" :class="{'cursor-pointer hover:shadow': original}">
    <span v-else class="block w-10 h-10 border rounded flex items-center text-gray-300 text-xl">
      <i class="fa fa-images mx-auto"></i>
    </span>
    <TransitionRoot v-if="original" as="template" :show="open">
      <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[86%] sm:w-auto sm:p-6 relative">
              <div>
                <img :src="original" alt=" " class="rounded object-contain mx-auto" crossorigin="anonymous">
                <p v-if="alt" class="text-gray-500 italic text-sm leading-6">{{ alt }}</p>
              </div>
              <span @click="open = false" class="absolute cursor-pointer z-10 w-8 h-8 -top-4 shadow border -right-4 rounded-full bg-white text-center leading-7 text-2xl">&times;</span>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script>
import {defineComponent, onMounted, ref, watch} from "vue";
import {isObject} from "element-plus/es/utils/util";
import {Dialog, DialogOverlay, DialogTitle, TransitionRoot, TransitionChild} from "@headlessui/vue";

export default defineComponent({
  name: 'iImage',
  components: {Dialog, DialogOverlay, DialogTitle, TransitionRoot, TransitionChild},
  props: {
    src: {type: [String, Object], default: null},
    type: {type: String, default: 'thumbnail'},
    //  Use for quick upload
    targetId: {type: Number, default: val => {return val >>> 0}},
    target: {type: String, default: null},
    alt: {type: String, defaul: null},
  },
  setup(props) {
    const open = ref(false)

    const alt = ref(null)
    const original = ref(null)
    const url = ref(null)

    /** Todo: make quick upload
     * When mouse hover => show icon => Click to select image => upload
     */

    /** Show image by input
     * @param {Object} obj
     * @return {String}
     */
    const showUrl = obj => {
      let url

      if (props.type === 'cover') {
        url = obj.cover || null
      } else
      if (props.type === 'url') {
        url = obj.url || null
      } else
      {
        url = obj.thumb_url || null
      }
      alt.value = props.alt || obj.name || null
      original.value = obj.url || null

      return url
    }

    onMounted(() => {
      if (isObject(props.src)) {
        url.value = showUrl(props.src)
      } else {
        url.value = props.src
      }
    })

    watch(() => props.src, val => {
      if (val) {
        if (isObject(val)) {
          url.value = showUrl(val)
        } else {
          url.value = val
        }
      }
    })

    return {
      open, alt,
      url, original,
    }
  }
})
</script>
