<template>
    <li v-if="node.id" v-loading="loading" class="border-l transition">
        <div class="flex items-center hover:bg-gray-100 py-1.5 px-3">
      <span class="h-5 w-5">
        <template v-if="node.children_count > 0">
          <svg v-if="!isOpen" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </template>
      </span>
            <div @click="getFolderTree(node.id)" role="button" class="flex-1 flex items-center">
                <span class="hover:text-cyan-600">{{ node.name }}</span>
                <span v-if="node.rank" class="ml-4 text-sm text-green-800">{{ node.rank.name_vietnamese || node.rank.name }}</span>
            </div>

            <div class="flex items-center">
                <template v-if="rule(makeFirstLower() + '.update')">
                    <el-tooltip :content="$t('nav.b.edit')" effect="primary" placement="top">
                        <router-link :to="{name: table.r.u, params: {id: node.id}}" :title="$t('nav.b.edit')">
              <span class="text-blue-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                  <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                </svg>
              </span>
                        </router-link>
                    </el-tooltip>
                </template>
                <template v-if="rule(makeFirstLower() + '.delete')">
                    <el-divider class="mx-3" direction="vertical"></el-divider>
                    <el-popconfirm :title="$t('adm.t.delete-confirm')" @confirm="table.del(node.id)">
                        <template #reference>
                    <span class="cursor-pointer text-rose-500">
                       <i class="fa fa-trash-alt"></i>
                     </span>
                        </template>
                    </el-popconfirm>
                </template>
            </div>
        </div>
        <ul v-if="data.length > 0" class="m-0 pl-6 list-none py-0">
            <folder-node v-for="item in data" :key="item.id" :node="item" :url="url" :table="table"></folder-node>
        </ul>
    </li>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import ajax from "@/ajax";
import {iAlert} from "@/mixins/utils";
import useState from "@/store";

export default defineComponent({
    name: 'FolderNode',
    props: {
        node: {type: Object, default: {}},
        root: {type: Boolean, default: false},
        url : {type: String, default: ''},
        table: {type: Object, default: null},
    },
    setup(props) {
        const data = ref([])
        const loading = ref(false)
        const isOpen = ref(false)
        const {rule} = useState()

        /** Get folder tree via Id */
        const getFolderTree = (id = 0) => {
            if (isOpen.value === false && props.node.children_count > 0) {
                loading.value = true
                ajax.get(`${props.url}/folder-tree/`  + id).then(res => {
                    if (!res.data.error) {
                        data.value = res.data.data
                        if (data.value.length > 0) {
                            isOpen.value = true
                        }
                    }
                    if (res.data.message) {
                        iAlert(res.data)
                    }
                    loading.value = false
                }).catch(err => {
                    iAlert(err, true)
                    loading.value = false
                })
            } else {
                isOpen.value = false
                data.value = []
            }
        }

        const makeFirstLower = () => {
            let table = props.table.tb
            return table.charAt(0).toLowerCase() + table.slice(1)
        }

        onMounted(() => {
            if (props.node.children) {
                data.value = props.node.children
                isOpen.value = true
            }
        })

        return {
            data, loading, isOpen,
            rule, getFolderTree, makeFirstLower,
        }
    }
})
</script>
