// in HTTP.js
import axios from 'axios'
import { apiBase } from './config/base'
import NProgress from 'nprogress'
import Cookies from 'js-cookie'

const token = Cookies.get('token');
const axiosConfig = {
  baseURL: apiBase,
  timeout: 30000,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Accept-Language': Cookies.get('locale') || 'vi',
  },
}
if (token !== null && token !== undefined) {
  axiosConfig.headers.Authorization = `Bearer ${token}`
}

// create a new axios instance
const ajax = axios.create(axiosConfig);

// before a request is made start the nprogress
ajax.interceptors.request.use((config) => {
  NProgress.start();
  return config;
});

// before a response is returned stop nprogress
ajax.interceptors.response.use((response) => {
  NProgress.done();
  return response;
});

export default ajax;
